import React from 'react';
import { camelizeKeys } from 'humps';
import { shape } from 'prop-types';
import { graphql } from 'gatsby';
import { isEmpty } from 'lodash';

import Layout from '../../components/Layout';
import DynamicZone from '../../components/DynamicZone';
import Seo from '../../components/seo';

const Home = (props) => {
  const { data, pageContext } = props;
  const { homePage } = camelizeKeys(data.strapi);
  const headerData = data.strapi && data.strapi.header;
  const footerData = data.strapi && data.strapi.footer;
  const signUpData = data.strapi && data.strapi.signUp;

  const seo = homePage && homePage.seo;

  const eventBannersBetweenNews = homePage
    && homePage.content
    && homePage.content.filter(
      (c) => c.typename
        === 'STRAPI_ComponentSectionsBannerWithOneEventBetweenTheNews',
    );

  return (
    <Layout
      containerClassName="home-page-layout"
      headerData={headerData}
      footerData={footerData}
      signUpData={signUpData}
    >
      {seo && (
        <Seo
          title={seo?.title}
          description={seo?.description}
        />
      )}
      {homePage && !isEmpty(homePage.content) && (
        <div className="home-page-wraper">
          <DynamicZone
            components={homePage.content}
            data={{ news: pageContext?.news }}
            eventBannersBetweenNews={eventBannersBetweenNews}
          />
        </div>
      )}
    </Layout>
  );
};

Home.propTypes = {
  data: shape({
    strapi: shape(),
  }),
  pageContext: shape(),
};

Home.defaultProps = {
  data: {
    strapi: {},
  },
  pageContext: {},
};

export default Home;

export const homeQuery = graphql`
  query getHomePage {
    strapi {
      signUp {
        title
        label
        buttonLabel
        backgroundImage {
          url
        }
      }
      footer {
        id
        links {
          id
          label
          path
          openInNewWindow
          offerIdEverflow
          data {
            path
            label
            openInNewWindow
          }
        }
        socialLinksTitle
        logo {
          id
          url
        }
        socialLinks {
          label
          id
          socialLink {
            path
            label
            logo {
              id
              url
            }
            hoverImage {
              id
              url
            }
          }
        }
        linksTitle
      }
      header {
        id
        links {
          id
          isRed
          linkData {
            id
            label
            path
            openInNewWindow
            offerIdEverflow
            data {
              id
              label
              path
              openInNewWindow
            }
          }
        }
        socialLinks {
          id
          label
          socialLink {
            path
            label
            logo {
              url
            }
          }
        }
        logo {
          url
        }
      }
      homePage {
        id
        seo {
          description
          id
          title
        }
        content {
          ... on STRAPI_ComponentSectionsBannerWithOneEvent {
            id
            __typename
            color {
              hex
            }
            mobileBackground {
              url
            }
            data {
              bannerLinks {
                id
                label
                path
                openInNewWindow
                offerIdEverflow
                data {
                  id
                  label
                  path
                  openInNewWindow
                }
              }
              event {
                id
                label
                data {
                  date
                  dateClock
                  dateDayOfWeek
                  dateMonth
                  dateTimezone
                  id
                  slug
                  title
                  description
                  image {
                    url
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsBannerWithOneEventBetweenTheNews {
            id
            afterNewsIndex
            color {
              hex
            }
            mobileBackground {
              url
            }
            __typename
            data {
              bannerLinks {
                id
                label
                path
                openInNewWindow
                offerIdEverflow
                data {
                  id
                  label
                  path
                  openInNewWindow
                }
              }
              event {
                id
                label
                data {
                  date
                  dateClock
                  dateDayOfWeek
                  dateMonth
                  dateTimezone
                  id
                  slug
                  title
                  description
                  image {
                    url
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsEventBanner {
            id
            __typename
            backgroundText
            color {
              hex
            }
            button {
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                path
                openInNewWindow
                label
              }
            }
            slides {
              id
              bannerLinks {
                id
                label
                path
                openInNewWindow
                offerIdEverflow
                data {
                  label
                  path
                  openInNewWindow
                }
              }
              event {
                label
                id
                data {
                  date
                  dateClock
                  dateDayOfWeek
                  dateMonth
                  dateTimezone
                  slug
                  image {
                    url
                  }
                  description
                  id
                  title
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsNewsBanner {
            id
            __typename
            backgroundText
            color {
              hex
            }
            numberOfNews
            button {
              label
              path
              openInNewWindow
              data {
                label
                path
                openInNewWindow
              }
            }
            relatedNews {
              label
              id
              data {
                date
                isFeatured
                slug
                description
                id
                title
                created_at
                published_at
                type {
                  label
                }
                primaryImage {
                  url
                }
                secondaryImage {
                  url
                }
                commonImage {
                  url
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsHomePageNewsHero {
            id
            __typename
            button {
              id
              label
              path
              openInNewWindow
              data {
                label
                path
                openInNewWindow
              }
            }
            newsBackgroundImage {
              url
            }
            mainNews {
              label
              data {
                id
                description
                date
                title
                slug
                type {
                  label
                }
                primaryImage {
                  url
                }
                secondaryImage {
                  url
                }
                commonImage {
                  url
                }
              }
            }
            relatedNews {
              label
              data {
                id
                description
                date
                primaryImage {
                  url
                }
                slug
                title
                type {
                  label
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsHomePageSpecialEventV1Hero {
            id
            __typename
            button {
              id
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                label
                path
                openInNewWindow
              }
            }
            newsBackgroundImage {
              url
            }
            bannerLinks {
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                id
                label
                path
                openInNewWindow
              }
            }
            relatedEvent {
              label
              data {
                id
                title
                slug
                description
                date
                dateClock
                dateDayOfWeek
                dateMonth
                dateTimezone
                image {
                  url
                }
                additionalNews {
                  label
                  data {
                    id
                    title
                    slug
                    type {
                      label
                    }
                    date
                    secondaryImage {
                      url
                    }
                    primaryImage {
                      url
                    }
                    commonImage {
                      url
                    }
                  }
                }
              }
            }
          }
          ... on STRAPI_ComponentSectionsHomePageSpecialEventV2Hero {
            id
            __typename
            newsBackgroundImage {
              url
            }
            button {
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                label
                path
                openInNewWindow
              }
            }
            bannerLinks {
              label
              path
              openInNewWindow
              offerIdEverflow
              data {
                label
                path
                openInNewWindow
                id
              }
            }
            relatedEvent {
              label
              data {
                date
                dateClock
                dateDayOfWeek
                dateMonth
                dateTimezone
                title
                slug
                id
                image {
                  url
                }
                additionalNews {
                  label
                  data {
                    title
                    type {
                      label
                    }
                    slug
                    date
                    id
                    primaryImage {
                      url
                    }
                    secondaryImage {
                      url
                    }
                    commonImage {
                      url
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;
