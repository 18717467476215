import React from 'react';
import { string } from 'prop-types';
import { Helmet } from 'react-helmet';

const SEO = ({
  description, lang, title,
}) => {
  let metaSitemap = '';
  if (typeof window !== 'undefined') {
    metaSitemap = (window.location.pathname === '/sitemap') ? 'noindex, follow, noarchive' : 'all';
  }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      meta={[
        {
          name: 'robots',
          content: metaSitemap,
        },
        {
          name: 'description',
          content: description,
        },
        {
          property: 'og:title',
          content: title,
        },
        {
          property: 'og:description',
          content: description,
        },
        {
          property: 'og:type',
          content: 'website',
        },
        {
          name: 'viewport',
          content: 'width=device-width, initial-scale=1, minimum-scale=1, maximum-scale=1, user-scalable=0',
        },
      ]}
    />
  );
};

SEO.propTypes = {
  lang: string,
  title: string,
  description: string,
};

SEO.defaultProps = {
  lang: 'en',
  title: '',
  description: '',
};

export default SEO;
